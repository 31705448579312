// import Vue from "vue";
import axios from "axios";
import store from "../store";


const accessToken = localStorage.getItem("access-token");
const branch = localStorage.getItem("currentbranch");

export default axios.create({
  baseURL: store.state.endpointURL + 'api/v1/' + store.state.Settings.language + "/",
  headers: {
    Accept: "application/json",
    "access-token": store.state.apiAccess,
    currentbranch: branch,
    Authorization: "Bearer " + accessToken,
  },
});
