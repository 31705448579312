import { render, staticRenderFns } from "./ShiftClosePage.vue?vue&type=template&id=6a892990&scoped=true&"
import script from "./ShiftClosePage.vue?vue&type=script&lang=js&"
export * from "./ShiftClosePage.vue?vue&type=script&lang=js&"
import style0 from "./ShiftClosePage.vue?vue&type=style&index=0&id=6a892990&prod&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "6a892990",
  null
  
)

export default component.exports